import { observer } from 'mobx-react-lite';
import Popover from './Popover';
import { mainStore } from 'stores/MainStore';
import { authStore } from 'stores/AuthStore';
import { CMS_URL, WMS_URL } from 'config';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { t } = useTranslation();
  const logout = () => authStore.logout();
  const companyConfig = authStore.companyConfig;

  return (
    <Popover className="popover-menu _in-top" isShow={mainStore.isShowPopoverMenu}>
      {!companyConfig.hideCMSLink && (
        <a
          className="popover-menu__item"
          href={CMS_URL}
          target="_blank"
          rel="noreferrer"
          data-link="admin-panel"
        >
          <span className="icon icon-dashboard" />
          {t('common:adminPanel')}
        </a>
      )}
      {!companyConfig.hideWMSLink && (
        <a
          className="popover-menu__item"
          href={WMS_URL}
          target="_blank"
          rel="noreferrer"
          data-link="warehouse-management"
        >
          <span className="icon icon-home" />
          {t('common:warehouseManagment')}
        </a>
      )}
      <div className="popover-menu__line" />
      <div className="popover-menu__item" onClick={logout} data-button="logout">
        <span className="icon icon-exit" />
        {t('common:logout')}
      </div>
    </Popover>
  );
});
