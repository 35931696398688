export const LOCALE = {
  en: {
    img: `https://s3.eu-west-2.amazonaws.com/illustrations.jiffy.team/languages/en.png`,
    title: 'EN',
  },
  ar: {
    img: `https://s3.eu-west-2.amazonaws.com/illustrations.jiffy.team/languages/ar.png`,
    title: 'AR',
  },
  fr: {
    img: `https://s3.eu-west-2.amazonaws.com/illustrations.jiffy.team/languages/fr.png`,
    title: 'FR',
  },
};
