import { Buffer } from 'buffer';
import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import { getAllEnumKeys } from 'enum-for';

import { JobState } from 'api/types/Job';
import { AuthRequests, User } from 'api/Auth';
import { mainStore } from './MainStore';
import { jobs } from 'proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';
import { JOB_STATE_FILTER_DEFAULT } from 'config';
import { COMPANY, defaultCompanyId } from 'company/constants';
import { CompanyConfig } from 'company/interface';

class AuthStore {
  token = '';
  refreshToken = '';
  tokensAreRefreshing = false;
  personalData: Omit<User, 'createdAt'> = {
    id: '',
    phone: '',
    login: '',
    email: '',
    firstName: '',
    lastName: '',
    roles: [],
    permissions: [],
  };
  jobStateFilter: Record<JobState, boolean> = toJS(this.jobStateFilterDefault);
  isSummaryExpanded = false;
  activeTeamId = '';
  isExpandedOffline = false;
  expandedCourierIds: string[] = [];
  isFrameTeamsHidden: boolean | null = null;
  companyId = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'AuthStore',
      properties: [
        'token',
        'refreshToken',
        'personalData',
        'jobStateFilter',
        'isSummaryExpanded',
        'activeTeamId',
        'isExpandedOffline',
        'expandedCourierIds',
        'isFrameTeamsHidden',
      ],
      storage: window.localStorage,
    })
      .then(() => {
        if (Object.keys(this.jobStateFilter).length !== getAllEnumKeys(jobs.State).length) {
          this.resetFilter();
        }
        this.setCompanyId();
      })
      .catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get isAuthorized(): boolean {
    return !!this.token;
  }

  get fullName(): string {
    return `${this.personalData.firstName} ${this.personalData.lastName}`.trim();
  }

  get companyConfig(): CompanyConfig {
    return COMPANY[this.companyId] || this.defaultCompanyConfig;
  }

  get defaultCompanyConfig(): CompanyConfig {
    const host = window.location.hostname;
    const urlParams = new URLSearchParams(window.location.search);
    const company = urlParams.get('company');
    const companyConfig = Object.keys(COMPANY).find((companyId) => {
      return COMPANY[companyId].name === company || COMPANY[companyId].hostname === host;
    });
    if (companyConfig) {
      return COMPANY[companyConfig];
    }

    return COMPANY[defaultCompanyId];
  }

  get showLangSelector(): boolean {
    return this.companyConfig.langs.length > 1;
  }

  get jobStateFilterDefault(): Record<JobState, boolean> {
    const obj: Record<JobState, boolean> = {
      UNASSIGNED: true,
      ASSIGNED: true,
      IN_PROGRESS: true,
      COMPLETED: true,
      CANCELLED: true,
      FAILED: true,
    };

    const jobStates = Object.keys(JobState) as JobState[];
    jobStates.forEach((state: JobState) => {
      obj[state] = JOB_STATE_FILTER_DEFAULT.includes(state);
    });
    return obj;
  }

  // Setters
  setToken(val: string) {
    this.token = val;
  }

  setCompanyId() {
    const authToken = this.token.split('.')[1];
    if (authToken) {
      const val = JSON.parse(Buffer.from(authToken, 'base64').toString() || '{}');
      if (val.companyId) {
        this.companyId = val.companyId;
      }
    }
  }

  setRefreshToken(val: string) {
    this.refreshToken = val;
  }

  setTokensAreRefreshing(areRefreshing: boolean) {
    this.tokensAreRefreshing = areRefreshing;
  }

  setPersonalData(val: User | null) {
    if (!val) {
      this.personalData = {
        id: '',
        phone: '',
        login: '',
        email: '',
        firstName: '',
        lastName: '',
        roles: [],
        permissions: [],
      };
      return;
    }
    val.login = val.login || '';
    this.personalData = val;
  }

  setJobStateFilter(val: Record<JobState, boolean>) {
    this.jobStateFilter = val;
  }

  setIsSummaryExpanded(flag: boolean) {
    this.isSummaryExpanded = flag;
  }

  setActiveTeamId(val: string) {
    this.activeTeamId = val;
  }

  setIsExpandedOffline(flag: boolean) {
    this.isExpandedOffline = flag;
  }

  setExpandedCourierIds(val: string[]) {
    this.expandedCourierIds = val;
  }

  setIsFrameTeamsHidden(flag: boolean | null) {
    this.isFrameTeamsHidden = flag;
  }

  // Actions
  resetFilter() {
    this.jobStateFilter = toJS(this.jobStateFilterDefault);
  }

  resetStore() {
    this.setPersonalData(null);
    this.setRefreshToken('');
    this.setToken('');
    this.resetFilter();
    this.setIsSummaryExpanded(false);
    this.setActiveTeamId('');
    this.setIsExpandedOffline(true);
    this.setExpandedCourierIds([]);
    this.setIsFrameTeamsHidden(null);
  }

  addExpandedCourierId(id: string) {
    if (!this.expandedCourierIds.includes(id)) this.expandedCourierIds.push(id);
  }

  removeExpandedCourierId(id: string) {
    this.setExpandedCourierIds(this.expandedCourierIds.filter((courierId) => courierId !== id));
  }

  async requestAuthByEmail({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> {
    try {
      const {
        data: { access_token, refresh_token },
      } = await AuthRequests.authByEmail({
        email,
        password,
      });

      this.setRefreshToken(refresh_token);
      this.setToken(access_token);
      this.setCompanyId();
      const { data: personalData } = await AuthRequests.requestAuthUserData();
      if (!personalData) this.logout();
      else this.setPersonalData(personalData);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'verifyAuthCode');
      return Promise.reject();
    }
  }

  // Requests API
  async requestAuthCode(phone: string): Promise<void> {
    // if (phone.slice(0, 2) === '07') phone = '44' + phone.slice(1);
    try {
      await AuthRequests.requestOtpCode({ phone });
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestAuthCode');
      return Promise.reject();
    }
  }

  async verifyAuthCode(phone: string, code: string): Promise<void> {
    if (phone.slice(0, 2) === '07') phone = '44' + phone.slice(1);
    try {
      const {
        data: { access_token, refresh_token },
      } = await AuthRequests.otpCodeConfirm({
        phone,
        code,
      });
      this.setRefreshToken(refresh_token);
      this.setToken(access_token);
      this.setCompanyId();
      const { data: personalData } = await AuthRequests.requestAuthUserData();
      if (!personalData) this.logout();
      else this.setPersonalData(personalData);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'verifyAuthCode');
      return Promise.reject();
    }
  }

  logout() {
    this.resetStore();
  }

  requestNewTokens() {
    return AuthRequests.refreshTokens({ refresh_token: this.refreshToken });
  }
}

export const authStore = new AuthStore();
