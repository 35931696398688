import { CompanyConfig, CompanyName } from './interface';

import LogoJiffy from '../assets/img/logo_jiffy_white.svg';
import LogoCirclek from '../assets/company/logo/logo_circlek.svg';

import LogoLocalee from '../assets/company/logo/logo_localee.svg';
import LogoOurKids from '../assets/company/logo/logo_ourkids.png';
import LogoCitydrinks from '../assets/company/logo/logo_citydrinks.svg';
import LogoVilo from '../assets/company/logo/logo_vilo.svg';

import LogoCloudRetail from '../assets/company/logo/logo_cloudretail.svg';

import LogoArabianMile from '../assets/company/logo/logo_arabianmile.png';
import LogoCarrefour from '../assets/company/logo/logo_carrefour.png';

//FIXME: move to .env
export const defaultCompanyId = '3257e6a2-87f1-46fe-b498-72d6b78b379b';

export const COMPANY: Record<string, CompanyConfig> = {
  'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d': {
    id: 'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Jiffy,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoCloudRetail,
    logoWidth: 120,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '3257e6a2-87f1-46fe-b498-72d6b78b379b': {
    id: '3257e6a2-87f1-46fe-b498-72d6b78b379b',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.CloudRetail,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'frontend-delivery.jiffygrocery.co.uk',
    logo: LogoCloudRetail,
    logoWidth: 120,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '0e715914-1b33-4387-92db-fe3103f865bb': {
    id: '0e715914-1b33-4387-92db-fe3103f865bb',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Baqal,

    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '49589d9f-753a-421f-bd36-cea6202c4ba7': {
    id: '49589d9f-753a-421f-bd36-cea6202c4ba7',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Swifft,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  'd05f30ba-ceb5-4633-a3b6-aa0f9463954f': {
    id: 'd05f30ba-ceb5-4633-a3b6-aa0f9463954f',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Localee,
    defaultPhoneCode: '+1',
    phonePlaceholder: '+1-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoLocalee,
    logoWidth: 75,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '1eb53a13-5f9e-4deb-92d7-090a4b53fd21': {
    id: '1eb53a13-5f9e-4deb-92d7-090a4b53fd21',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.CityDrinks,
    defaultPhoneCode: '+971',
    phonePlaceholder: '+971-(__)-______',
    hostnames: ['*'],
    hostname: 'frontend-delivery.citydrinks.com',
    logo: LogoCitydrinks,
    logoWidth: 120,

    hideCMSLink: false,
    hideWMSLink: false,
  },
  '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13': {
    id: '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13',
    lang: 'ar',
    langs: ['en', 'ar'],
    name: CompanyName.CircleK,

    defaultPhoneCode: '+20',
    phonePlaceholder: '+20-(__)-______',

    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoCirclek,
    logoWidth: 75,

    hideCMSLink: false,
    hideWMSLink: false,
  },
  'a1afcf22-db07-4c78-8e40-4ada483e1805': {
    id: 'a1afcf22-db07-4c78-8e40-4ada483e1805',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Vilo,
    defaultPhoneCode: '+62',
    phonePlaceholder: '+62-(__)-______',

    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoVilo,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  'fca60c82-0022-49e4-aee6-5ec196acd111': {
    id: 'fca60c82-0022-49e4-aee6-5ec196acd111',
    lang: 'ar',
    langs: ['en', 'ar'],
    name: CompanyName.OurKids,
    defaultPhoneCode: '+20',
    phonePlaceholder: '+20-(__)-______',

    hostnames: ['*'],
    hostname: 'hostname',

    logo: LogoOurKids,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },

  avantgardeFIXME: {
    id: 'avantgardeFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.AvantGarde,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  rkbFIXME: {
    id: 'rkbFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.RKBFruit,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '11f1cb17-bea9-477a-b921-5a0fd7e7fa36': {
    id: '11f1cb17-bea9-477a-b921-5a0fd7e7fa36',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.OkieElectronics,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  indimarketFIXME: {
    id: 'indimarketFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.IndiMarket,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '43e0e0f4-0032-4658-a607-7a8b6ed89013': {
    id: '43e0e0f4-0032-4658-a607-7a8b6ed89013',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Oskuhus,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  helenDoronFIXME: {
    id: 'helenDoronFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.HelenDoron,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  '8b4b64e1-0e88-4889-b75a-1367bfb9baea': {
    id: '8b4b64e1-0e88-4889-b75a-1367bfb9baea',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Heimkaup,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  'ad8985b1-b990-4337-9bc9-a8084141e8a1': {
    id: 'ad8985b1-b990-4337-9bc9-a8084141e8a1',
    lang: 'en',
    langs: ['en', 'ar'],
    name: CompanyName.ArabianMile,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoArabianMile,
    logoWidth: 80,
    hideCMSLink: false,
    hideWMSLink: true,
  },
  qwabeFIXME: {
    id: 'qwabeFIXME',
    lang: 'en',
    langs: ['en'],
    name: CompanyName.Qwabe,
    defaultPhoneCode: '+44',
    phonePlaceholder: '+44-(__)-______',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoJiffy,
    logoWidth: 50,
    hideCMSLink: false,
    hideWMSLink: false,
  },
  'd93a174a-d1f7-4504-97d5-c075dd86d0f0': {
    id: 'd93a174a-d1f7-4504-97d5-c075dd86d0f0',
    lang: 'fr',
    langs: ['en', 'fr'],
    name: CompanyName.Carrefour,
    defaultPhoneCode: '+241',
    phonePlaceholder: '+241-________',
    hostnames: ['*'],
    hostname: 'hostname',
    logo: LogoCarrefour,
    logoWidth: 80,
    hideCMSLink: false,
    hideWMSLink: true,
  },
};
