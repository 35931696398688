import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLang } from '../../hooks/useLang';
import { LOCALE } from './constants';
import styles from './LanguageSelector.module.scss';
import { mainStore } from 'stores/MainStore';
import { authStore } from 'stores/AuthStore';

const CHECKMARK = '✓';

const LanguageSelector = () => {
  const { setLanguage } = useLang();
  const [isOpen, setIsOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('');

  useEffect(() => {
    setCurrentLang(mainStore.lang || authStore.companyConfig?.lang || 'en');
  }, []);

  if (!authStore.companyConfig?.langs || authStore.companyConfig?.langs.length < 2) {
    return null;
  }

  const handleChangeLang = (lang: string) => {
    setCurrentLang(lang);
    setLanguage(lang);
    setIsOpen(false);
  };

  const currentLocale = LOCALE[currentLang as keyof typeof LOCALE];

  return (
    <div className={styles.root}>
      <button 
        className={styles.selector} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={currentLocale?.img}
          alt={currentLocale?.title}
        />
        <span>{currentLocale?.title}</span>
      </button>

      {isOpen && (
        <div className={styles.dropdown}>
          {authStore.companyConfig.langs.map((lang) => {
            const locale = LOCALE[lang as keyof typeof LOCALE];
            return (
              <button
                key={lang}
                className={styles.option}
                onClick={() => handleChangeLang(lang)}
              >
                <img src={locale?.img} alt={locale?.title} />
                <span>{locale?.title}</span>
                {currentLang === lang && (
                  <span className={styles.checkmark}>{CHECKMARK}</span>
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default observer(LanguageSelector);
