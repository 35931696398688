export enum CompanyName {
  ColorBlocks = 'colorblocks',
  CloudretailWMS = 'cloudretail_wms',
  CityDrinks = 'citydrinks',
  Localee = 'localee',
  CircleK = 'circlek',
  OurKids = 'ourkids',
  Vilo = 'vilo',
  AvantGarde = 'avantgarde',
  RKBFruit = 'rkbfruit',
  OkieElectronics = 'okieelectronics',
  Oskuhus = 'oskuhus',
  Heimkaup = 'heimkaup',
  NewTestCompany = 'newTestCompany',
  ArabianMile = 'arabianmile',
  Carrefour = 'carrefour',
  IndiMarket = 'indimarket',
  HelenDoron = 'helendoron',
  CloudRetail = 'cloudretail',
  Jiffy = 'jiffy',
  Baqal = 'baqal',
  Swifft = 'swifft',
  Qwabe = 'qwabe',
}

export type GtmConfig = { auth: string; preview: string };

export type CompanyConfig = {
  id: string;
  lang: string;
  langs: string[];
  name: CompanyName;
  defaultPhoneCode: string;
  phonePlaceholder: string;
  hostnames?: string[];
  hostname: string;
  logo: any;
  logoWidth: number;
  hideCMSLink: boolean;
  hideWMSLink: boolean;
};
